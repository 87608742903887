import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShellModule } from './shell/shell.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { DashboardeModule } from './module/dashboard/dashboard.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RequestOptions, XHRBackend } from '@angular/http';
import { HttpModule, JsonpModule } from '@angular/http';
import { HttpService } from './util/http/securehttp.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LoginModule } from './auth/login/login.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserModule } from './module/user/user.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ExcelService } from './file-saver/excel.service';
import { AuthorizationService } from './_services/authorization.service';
import { CountryModule } from '../app/module/country/country.module';
import { CurrencyModule } from '../app/module/currency/currency.module';
import { MerchantModule } from '../app/module/merchant/merchant.module';
import { PaymentMethodModule } from '../app/module/payment-method/payment-method.module';
// import { VoucherDenominationModule } from './module/voucher-denomination/voucher-denomination.module';
import { MerchantVoucherModule } from './module/merchant-voucher/merchant-voucher.module';
import { MerchantBannerModule } from './module/merchant-banner/merchant-banner.module';
import { ReportModule } from './module/reports/report.module';
import { MerchantRequestModule } from './module/merchant-request/merchant-request.module';
import { PaypointFetchDataModule } from '../app/module/paypoint-fetch-data/paypoint-fetch-data.module';
import { ContactUsModule } from './module/contact-us/contact-us.module';
import { AuthInterceptor } from './constant/authInterceptor.service';
import { DecodeInterceptor } from './constant/decodeInterceptor.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ModalModule.forRoot(),
    ShellModule,
    routing,
    DashboardeModule,
    LoginModule,
    UserModule,
    ReportModule,
    CountryModule,
    CurrencyModule,
    MerchantModule,
    PaymentMethodModule,
    // VoucherDenominationModule,
    MerchantVoucherModule,
    MerchantBannerModule,
    MerchantRequestModule,
    ContactUsModule,
    PaypointFetchDataModule,
    HttpModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: HttpService,
      useFactory: (backend: XHRBackend, options: RequestOptions, authorizationService: AuthorizationService) => {
        return new HttpService(backend, options, authorizationService);
      },
      deps: [XHRBackend, RequestOptions, AuthorizationService]
    },
    ExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: DecodeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
