import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';
import { MerchantVoucherService } from '../../service/merchant-voucher.service';
import { CountryService } from '../../../../module/country/service/country.service';
import { CountryMapping } from '../../model/country-mapping';

declare var M: any;
declare var $: any;

@Component({
  selector: 'merchant-voucher-detail',
  templateUrl: './merchantVoucher.html',
  providers: [MerchantVoucherService]
})
export class MerchantVoucherComponent implements OnInit {
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  public currencyList: any;
  public merchantList: any;
  public countryList: any;
  public categoryList: any;
  public mode: any;
  public voucher: any;
  countryMapping: CountryMapping = new CountryMapping();
  public merchantVoucherId: any;
  minDate: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute,
    private service: MerchantVoucherService, private changeDetector: ChangeDetectorRef, private countryService: CountryService) {
    this.route.data.subscribe(response => {
      // console.log(response);
      this.merchantList = response.data.merchant.payload.responseData.merchants;
      this.currencyList = response.data.currency.payload.responseData.currencies;
      // this.categoryList = response.data.category.payload.responseData.Data;
    });

    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.voucher = this.data.storage.voucher;
          } else {
            window.location.href = "#/merchant-voucher/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.merchantVoucherForm.disable();
          } else {
            window.location.href = "#/merchant-voucher/list";
            return;
          }
        } else {
          return;
        }
        this.voucher = this.data.storage.voucher;
      }
    });

    // Set minDate to the current date in the format 'yyyy-MM-dd'
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    this.minDate = `${year}-${month}-${day}`;
  }

  merchantVoucherForm = this.formBuilder.group({
    merchantVoucherID: [''],
    merchantId: ['', Validators.required],
    voucherName: ['', Validators.required],
    // catId: ['', Validators.required],
    denominationValue: ['', Validators.required],
    denominationCurrencyId: ['', Validators.required],
    commissionAmount: ['', Validators.required],
    discountPercentage: [''],
    sellDateFrom: ['', [Validators.required, this.startDateValidator.bind(this)]],
    sellDateTo: ['', [Validators.required, this.endDateValidator.bind(this)]],
    isActive: [true],
    // countries: [''],
    voucherType: ['', Validators.required],
  });

  get f() { return this.merchantVoucherForm.controls; }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }


  startDateValidator(control: AbstractControl): ValidationErrors | null {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in ISO format (YYYY-MM-DD)
    const selectedDate = control.value;
    if (selectedDate < currentDate) {
      return { startDateError: true };
    }
    return null;
  }

  endDateValidator(control: AbstractControl): ValidationErrors | null {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in ISO format (YYYY-MM-DD)
    const selectedDate = control.value;
    if (selectedDate < currentDate) {
      return { endDateError: true };
    }
    return null;
  }


  ngAfterViewInit(): void {
    this.getActiveCountries();
    setTimeout(() => {
      $('select').formSelect();
    }, 100);
    setTimeout(() => {
      $("#sellDateFrom").datepicker({ format: "yyyy-mm-dd" });
      $("#sellDateTo").datepicker({ format: "yyyy-mm-dd" });
    }, 500);
    if (this.mode != 'Add') {
      this.merchantVoucherForm.patchValue(this.voucher);
      M.updateTextFields();
      setTimeout(() => $('select').formSelect(), 100);
    }
  }

  // get countries

  getActiveCountries() {
    this.countryService.getActiveCountries().subscribe(res => {
      // console.log(res);
      if (res.code == 'VHR_SUCCESS_00') {
        this.countryList = res.responseData.countries;
        setTimeout(() => $(".select2").select2({
          dropdownAutoWidth: true,
          width: '100%',
          tags: true
        }), 300);
        if (this.mode != 'Add') {
          setTimeout(() => {
            $(".browser-default").val(this.countryMapping.countries);
            $(".browser-default").trigger('change');
          }, 1000);
        }
      } else {
      }
    });
  }

  onInputChange(event: any) {
    let value = event.target.value;

    // Remove leading zeros
    value = value.replace(/^0+/, '');

    // Remove extra decimal places
    const parts = value.split('.');
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, 2); // Keep only two decimal places
      value = parts.join('.');
    }

    // Restrict input to 99.99
    if (parseFloat(value) > 99.99) {
      value = '99';
    }

    event.target.value = value;
  }

  updateCountryMapping() {
    this.service.updateCountryMapping(this.countryMapping).subscribe(res => {
      if (res.payload.code == VHRResponse.SUCCESS) {
        console.log("SUCCESS");
      }
      else if (res.payload.code == VHRResponse.UNKNOWN_ERROR) {
        console.log("ERROR");
      }
    }, error => { console.log(error) });
  }

  onSubmit() {
    console.log(this.f);

    var fromDate = $("#sellDateFrom").val();
    var toDate = $("#sellDateTo").val();
    this.f.sellDateFrom.setValue(fromDate);
    this.f.sellDateTo.setValue(toDate);
    this.submitted = true;
    if (this.merchantVoucherForm.invalid) {
      return;
    }
    this.f.discountPercentage.setValue(this.f.commissionAmount.value)
    console.log(this.merchantVoucherForm.getRawValue());
    let body = this.merchantVoucherForm.getRawValue()
    this.service.saveMerchantVoucher(body).subscribe(response => {
      console.log(response);
      if (response.payload.code == VHRResponse.SUCCESS) {
        this.merchantVoucherId = response.payload.responseData.voucherId;
        console.log(this.merchantVoucherId);

        let selectedCountry = $(".browser-default").val();
        this.countryMapping.countries = selectedCountry;
        this.countryMapping.merchantVoucherId = this.merchantVoucherId;
        this.updateCountryMapping();
        this.isSuccess = true;
        this.message = this.mode == 'Add' ? "SUCCESS!  Created SuccessFully" : "SUCCESS!  Updated SuccessFully";
        setTimeout(() => {
          this.router.navigate(['merchant-voucher', 'list']);
        }, 1500)
      }
      else if (response.payload.code == VHRResponse.UNKNOWN_ERROR) {
        this.isError = true;
      } else {
        this.isError = true;
      }
    });
  }

}
