import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from '../auth/login/guard/login.guard';
import { DashboardComponent } from '../module/dashboard/component/dashboard.component';
import { PageNotFoundComponent } from '../module/dashboard/component/page-not-found.component';
import { LoginComponent } from '../auth/login/component/login.component';
import { ChangePasswordComponent } from './shared-components/change-password/change-password.component';
import { AuthorizationGuard } from '../auth/authorization/guard/authorization.guard';
import { CountryComponent } from '../module/country/component/detail/country.component';
import { CountryListComponent } from '../module/country/component/list/country-list.component';
import { CountryListResolver } from '../module/country/resolver/country-list.resolver';
import { CurrencyListResolver } from '../module/currency/resolver/currency-list.resolver';
import { CurrencyComponent } from '../module/currency/component/detail/currency.component';
import { CurrencyListComponent } from '../module/currency/component/list/currency-list.component';
import { MerchantListComponent } from '../module/merchant/component/list/merchant-list.component';
import { MerchantComponent } from '../module/merchant/component/detail/merchant.component';
import { PaymentMethodListComponent } from '../module/payment-method/component/list/payment-method-list.component';
import { PaymentMethodComponent } from '../module/payment-method/component/detail/payment-method.component';
// import { VoucherDenominationListComponent } from '../module/voucher-denomination/component/list/voucher-denomination-list.component';
// import { VoucherDenominationComponent } from '../module/voucher-denomination/component/detail/voucher-denomination.component';
import { MerchantVoucherListComponent } from '../module/merchant-voucher/component/list/merchant-voucher-list.component';
import { MerchantVoucherComponent } from '../module/merchant-voucher/component/detail/merchant-voucher.component';
import { MerchantResolver } from '../module/merchant/resolver/merchant.resolver';
import { PaymentMethodResolver } from '../module/payment-method/resolver/payment-method.resolver';
// import { VoucherDenominationResolver } from '../module/voucher-denomination/resolver/voucher-denomination.resolver';
import { MerchantVoucherResolver } from '../module/merchant-voucher/resolver/merchant-voucher.resolver';
import { PaymentMethodDetailResolver } from '../module/payment-method/resolver/payment-method-detail.resolver';
import { MerchantDetailResolver } from '../module/merchant/resolver/merchant-detail.resolver';
// import { VoucherDenominationListResolver } from '../module/voucher-denomination/resolver/voucher-denomication-list.resolver';
import { MerchantVoucherListResolver } from '../module/merchant-voucher/resolver/merchant-voucher-list.resolver';
import { DashboardResolver } from '../module/dashboard/resolver/dashboard.resolver';
import { MerchantBannerComponent } from '../module/merchant-banner/component/detail/merchant-banner.component';
import { MerchantBannerListComponent } from '../module/merchant-banner/component/list/merchant-banner-list.component';
import { MerchantBannerResolver } from '../module/merchant-banner/resolver/merchant-banner.resolver';
import { MerchantRequestListComponent } from '../module/merchant-request/component/list/merchant-request-list.component';
import { MerchantRequestComponent } from '../module/merchant-request/component/detail/merchant-request.component';
import { MerchantRequestListResolver } from '../module/merchant-request/resolver/merchant-request-list.resolver';
import { FetchCountryComponent } from '../module/paypoint-fetch-data/fetch-country/fetch-country.component';
import { FetchCompaniesComponent } from '../module/paypoint-fetch-data/fetch-companies/fetch-companies.component';
import { FetchVouchersComponent } from '../module/paypoint-fetch-data/fetch-vouchers/fetch-vouchers.component';
import { ContactUsListComponent } from '../module/contact-us/component/list/contact-us-list.component';
import { ContactUsListResolver } from '../module/contact-us/resolver/contact-us-list.resolver';

const routes: Routes = [

  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [LoggedInGuard],
    resolve: {
      data: DashboardResolver
    }
  },
  {
    path: "country/list",
    component: CountryListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MC' },
    resolve: {
      data: CountryListResolver
    }
  },
  {
    path: "country",
    component: CountryComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MC' }
  },
  {
    path: "currency/list",
    component: CurrencyListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MCU' },
    resolve: {
      data: CurrencyListResolver
    }
  },
  {
    path: "currency",
    component: CurrencyComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MCU' }
  },
  {
    path: "merchant/list",
    component: MerchantListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MM' },
    resolve: {
      data: MerchantResolver
    }
  },
  {
    path: "merchant",
    component: MerchantComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MM' },
    resolve: {
      data: MerchantDetailResolver
    }
  },
  {
    path: "payment-method/list",
    component: PaymentMethodListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MPM' },
    resolve: {
      data: PaymentMethodResolver
    }
  },
  {
    path: "payment-method",
    component: PaymentMethodComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MPM' },
    resolve: {
      data: PaymentMethodDetailResolver
    }
  },
  // {
  //   path: "voucher-denomination/list",
  //   component: VoucherDenominationListComponent,
  //   canActivate: [LoggedInGuard, AuthorizationGuard],
  //   data: { permission: 'VHR-MVD' },
  //   resolve: {
  //     data: VoucherDenominationListResolver
  //   }
  // },
  // {
  //   path: "voucher-denomination",
  //   component: VoucherDenominationComponent,
  //   canActivate: [LoggedInGuard, AuthorizationGuard],
  //   data: { permission: 'VHR-MVD' },
  //   resolve: {
  //     data: VoucherDenominationResolver
  //   }
  // },
  {
    path: "merchant-request/list",
    component: MerchantRequestListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MM' },
    resolve: {
      data: MerchantRequestListResolver
    }
  },
  {
    path: "merchant-request",
    component: MerchantRequestComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MM' },
  },
  {
    path: "contactUs/list",
    component: ContactUsListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MVD' },
    resolve: {
      data: ContactUsListResolver
    }
  },
  {
    path: "merchant-voucher/list",
    component: MerchantVoucherListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
    resolve: {
      data: MerchantVoucherListResolver
    }
  },
  {
    path: "merchant-voucher",
    component: MerchantVoucherComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
    resolve: {
      data: MerchantVoucherResolver
    }
  },
  {
    path: "merchant-banner/list",
    component: MerchantBannerListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MM' },
  },

  {
    path: "merchant-banner",
    component: MerchantBannerComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MM' },
    resolve: {
      data: MerchantBannerResolver
    }
  },

  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'UP-CP' },
    resolve: {}
  },
  {
    path: "fetch-paypoint/country",
    component: FetchCountryComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
  },
  {
    path: "fetch-paypoint/companies",
    component: FetchCompaniesComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
  },
  {
    path: "fetch-paypoint/vouchers",
    component: FetchVouchersComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
  },
  {
    path: '',
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

export const routing = RouterModule.forChild(routes);
